import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {ADMIN_HOME_PATH, LOGIN_PATH} from './application/types'

export const routes: Routes = [
  {
    path: ADMIN_HOME_PATH,
    loadChildren: () => import('./admin/admin.routes'),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_PATH,
    loadComponent: () => import('./components/login/login.component')
      .then(m => m.LoginComponent)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_PATH
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH
  }
]

import {AsyncPipe} from '@angular/common'
import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {ConfigService} from '../../services/config.service'
import {RouterLink} from '@angular/router'

@Component({
  selector: 'spb-header',
  standalone: true,
  templateUrl: './header.component.html',
  imports: [
    ThemeModule,
    AsyncPipe,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatButton,
    RouterLink
  ],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService
  ) {
  }
}
